import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITierItem } from '@interfaces';
import { BaseComponent } from '@root/core-components/base-component';
import { BehaviorSubject, map, takeUntil } from 'rxjs';

interface IItems {
  [key: string]: { name: string; enabled: boolean };
}

@Component({
  selector: 'resplendent-selector-card',
  templateUrl: './selector-card.component.html',
  styleUrls: ['./selector-card.component.scss'],
})
export class SelectorCardComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() set items(x: ITierItem[]) {
    this.itemsSource$.next(x);
  }
  @Input() expandText = 'Open';
  @Input() collapseText = 'Close';
  @Input() maxItems = 0;

  @Output() collapseEvent = new EventEmitter<ITierItem[]>();
  @Output() madeSelection = new EventEmitter<ITierItem[]>();

  private itemsSource$ = new BehaviorSubject<ITierItem[]>([]);
  public items$ = this.itemsSource$.asObservable();

  public totalSelected$ = this.items$.pipe(map((items) => Object.values(items).filter((i) => i.enabled).length));
  public totalUnselected$ = this.items$.pipe(map((items) => Object.values(items).filter((i) => !i.enabled).length));

  public isExpandedSource$ = new BehaviorSubject(false);
  public isExpanded$ = this.isExpandedSource$.asObservable();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.items$.pipe(takeUntil(this.isDestroyed$)).subscribe((items) => {
      this.madeSelection.emit(items);
    });
  }

  public select(key: string) {
    // Update item in state to be the opposite of what it is now
    const state = this.itemsSource$.getValue();
    state.find((item) => item.id === key).enabled = !state.find((item) => item.id === key).enabled;
    this.itemsSource$.next(state);
  }

  public finishSelection() {
    const state = this.itemsSource$.getValue();
    this.collapseEvent.emit(state);
  }

  public toggle() {
    const val = this.isExpandedSource$.getValue();
    this.isExpandedSource$.next(!val);
  }

  public expand() {
    this.isExpandedSource$.next(true);
  }

  public collapse() {
    this.isExpandedSource$.next(false);
    this.finishSelection();
  }
}
