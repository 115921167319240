import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbCheckboxModule, NbListModule, NbSpinnerModule } from '@nebular/theme';
import { GraphAnimatedBarLoaderModule } from '@scams/components/graph-animated-bar-loader/graph-animated-bar-loader.module';

import { FeatureItemSelectorComponent } from './feature-item-selector.component';
import { SelectorCardComponent } from './selector-card/selector-card.component';

@NgModule({
  declarations: [FeatureItemSelectorComponent, SelectorCardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', component: FeatureItemSelectorComponent }]),
    NbSpinnerModule,
    NbCardModule,
    NbListModule,
    NbButtonModule,
    NbCheckboxModule,
    GraphAnimatedBarLoaderModule,
  ],
  exports: [FeatureItemSelectorComponent],
})
export class FeatureItemSelectorModule {}
