import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IItemsForTier, ITierItem } from '@interfaces';
import { BaseComponent } from '@root/core-components/base-component';

import { StateService, WebsocketService } from '@services';

@Component({
  selector: 'resplendent-feature-item-selector',
  templateUrl: './feature-item-selector.component.html',
  styleUrls: ['./feature-item-selector.component.scss'],
})
export class FeatureItemSelectorComponent extends BaseComponent implements OnInit {
  public dashboards: ITierItem[];
  public widgets: ITierItem[];
  public users: ITierItem[];

  public maxDashboards = 0;
  public maxWidgets = 0;
  public maxUsers = 0;

  public canSave = false;

  @Input() expandText = 'Edit';
  @Input() collapseText = 'Save';

  @Output() saveEvent = new EventEmitter<null>();

  constructor(private ws: WebsocketService, private globalState: StateService) {
    super();
  }

  ngOnInit(): void {
    this.startLoading();
    this.ws
      .asyncRequest('GET_FEATURE_ITEM_STATE')
      .then(({ res, success }) => {
        if (success) {
          this.dashboards = res.items
            .filter((item) => item.type === 'dashboard')
            .sort((a, b) => a.name.localeCompare(b.name));
          this.widgets = res.items
            .filter((item) => item.type === 'widget')
            .sort((a, b) => a.name.localeCompare(b.name));
          this.users = res.items.filter((item) => item.type === 'user').sort((a, b) => a.name.localeCompare(b.name));
        }
        const userTier = this.globalState.sessionVariables.user.tier;
        const itemLimits = this.globalState.sessionVariables.tierInfo.tiers[userTier] as IItemsForTier;
        this.maxDashboards = itemLimits.dashboards;
        this.maxWidgets = itemLimits.widgets;
        this.maxUsers = itemLimits.users;
        this.checkCanSave();
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => this.stopLoading());
  }

  public setDashboards(dashboards: ITierItem[]) {
    this.dashboards = dashboards;
    this.checkCanSave();
  }
  public setWidgets(widgets: ITierItem[]) {
    this.widgets = widgets;
    this.checkCanSave();
  }
  public setUsers(users: ITierItem[]) {
    this.users = users;
    this.checkCanSave();
  }

  public async save() {
    this.startLoading();

    const promises = [
      this.ws.asyncRequest('SET_ENABLED_FEATURE_STATE', {
        items: [...this.dashboards, ...this.widgets, ...this.users],
      }),
    ];
    await Promise.all(promises);
    this.saveEvent.emit(null);

    this.stopLoading();
  }

  private checkCanSave() {
    const canSave =
      Object.values(this.dashboards).filter((v) => v.enabled).length <= this.maxDashboards &&
      Object.values(this.widgets).filter((v) => v.enabled).length <= this.maxWidgets &&
      Object.values(this.users).filter((v) => v.enabled).length <= this.maxUsers;

    this.canSave = canSave;
  }
}
