<nb-card class="component-container">
  <nb-card-header class="card-header">
    <h4>{{ title }}</h4>
    <div class="header-right-container">
      <h6 *ngIf="maxItems > 1000000">Unlimited</h6>
      <h6 *ngIf="maxItems <= 1000000">{{ totalSelected$ | async }}/{{ maxItems }}</h6>
      <button
        nbButton
        status="primary"
        (click)="toggle()"
        [disabled]="(totalSelected$ | async) > maxItems && (isExpanded$ | async)"
      >
        {{ (isExpanded$ | async) ? collapseText : expandText }}
      </button>
    </div>
  </nb-card-header>
  <nb-card-body *ngIf="isExpanded$ | async">
    <nb-list>
      <nb-list-item *ngFor="let item of items$ | async" class="list-item" (click)="select(item.id)">
        <h6>{{ item.name }}</h6>
        <nb-checkbox
          [checked]="item.enabled"
          color="gray"
          (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
          (change)="select(item.id)"
        ></nb-checkbox>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
</nb-card>
