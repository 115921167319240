<div class="component-container">
  <resplendent-graph-animated-bar-loader *ngIf="isLoading$ | async"></resplendent-graph-animated-bar-loader>
  <ng-container *ngIf="(isLoading$ | async) === false">
    <div class="toolbar-thing">
      <button nbButton (click)="save()" status="success" [disabled]="!canSave">Save</button>
    </div>
    <resplendent-selector-card
      title="Dashboards"
      [expandText]="expandText"
      [collapseText]="collapseText"
      [items]="dashboards"
      [maxItems]="maxDashboards"
      (collapseEvent)="setDashboards($event)"
      (madeSelection)="setDashboards($event)"
    ></resplendent-selector-card>
    <resplendent-selector-card
      title="Widgets"
      [expandText]="expandText"
      [collapseText]="collapseText"
      [items]="widgets"
      [maxItems]="maxWidgets"
      (collapseEvent)="setWidgets($event)"
      (madeSelection)="setWidgets($event)"
    ></resplendent-selector-card>
    <resplendent-selector-card
      title="Users"
      [expandText]="expandText"
      [collapseText]="collapseText"
      [items]="users"
      [maxItems]="maxUsers"
      (collapseEvent)="setUsers($event)"
      (madeSelection)="setUsers($event)"
    ></resplendent-selector-card>
  </ng-container>
</div>
